import React, {useState} from "react"
import {GatsbyImage, getImage, getSrc} from "gatsby-plugin-image"
import SubblockWrapper from "./subblock-wrapper";
import {Col, Row} from "react-bootstrap";
import ImageViewer from "../image-viewer/image-viewer";


// multiple centered pictures with a maximum height
const SubblockPictureLine = ({ content: pictures , showGallery}) => {

  const [photoIndex,setPhotoIndex] = useState(0)

  // optional pictures
  if (pictures === null)
    return <></>;

  const colums = [3,3,3,3,3,2]
  const pictureColum = pictures.length <= 5 ? colums[ pictures.length ] : 2;

  const imageUrlList = pictures.map((item) => getSrc(item.localFile));
  const mousepointer = showGallery ? "cursor-pointer" : ""
  const component =
    <Row className="justify-content-md-center align-items-center">
      {pictures.map( (picture, index)=>(
        <Col xs={12} lg={pictureColum} className="d-flex justify-content-center" key={index} onClick={()=>setPhotoIndex(index)} onKeyDown={()=>setPhotoIndex(index)} tabIndex={0}>
          <GatsbyImage
            image={getImage(picture.localFile)}
            alt={picture.alternativeText}
            imgStyle={{objectFit: "cover", width: "100%", height: "100%"}}
            className={mousepointer}
          />
        </Col>
      ))}
    </Row>

  return (
    <>
      {/* TODO: find a solution for col-lg-3, for x pictures */}
      <SubblockWrapper fullwidth position="center">
        {showGallery ? (
          <ImageViewer imageUrls={imageUrlList} photoIndex={photoIndex} setPhotoIndex={setPhotoIndex}>
            {component}
          </ImageViewer>
        ): (
          <>{component}</>
        )}
      </SubblockWrapper>
    </>
  )
}

export default SubblockPictureLine
