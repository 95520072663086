import React from 'react';

import SubblockWrapper from "./subblock-wrapper";
import RichText from "../basics/rich-text";


const SubblockRichText =({content, position, fullwidth, className}) => {
  return (
    <>
      <SubblockWrapper position={position} fullwidth={fullwidth} >
        <RichText className={className} content={content} />
      </SubblockWrapper>
    </>
  );
}

export default SubblockRichText;
