import React from 'react';

const RichText =({content, className}) => {
  return (
    <>
        <div
          className={className ? className : ""}
          dangerouslySetInnerHTML={{
            __html: content.data.childMarkdownRemark.html,
          }}
        />
    </>
  );
}

export default RichText;
