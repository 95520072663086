import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import SubblockRichText from "../components/common/subblock/subblock-rich-text";
import SectionLayout from "../components/common/layout/sectionLayout";
import SubblockPictureLine from "../components/common/subblock/subblock-picture-line";

const SosKinderdorf = ({ data }) => {
  const {header, text, picture } = data.strapiSosKinderdorf

  return (
    <>
      <Seo title="SOS-Kinderdorf" />
      <Layout>
        <SectionLayout>
          <SubblockPageHeader content={header} />
          <SubblockRichText content={text} position="center" fullwidth />
          <SubblockPictureLine content={[picture]} showGallery />
        </SectionLayout>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiSosKinderdorfQuery {
  strapiSosKinderdorf {
    header {
      ...fragmentHeader
    }
    text {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
    picture {
      mime
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`

export default SosKinderdorf
